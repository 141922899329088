export const endPoints = {
    "LOGIN": "/v1/account/login",
    "ENCRYPT_OBJECT": "/v1/rest/shared/encrypt-object",
    "DECRYPT_OBJECT": "/v1/rest/shared/decrypt-object",

    // Home Page
    "GET_BANNER": "/v1/rest/en-us/banner",
    "GET_RESOURCES": "/v1/rest/en-us/Resources",
    "GET_TUTOR": "/v1/rest/en-us/tutor",
    "GET_TUTOR_ORDER_BY_ASC": "/v1/rest/en-us/tutor/getTutorbyOrder",
    "GET_NRI": '/v1/rest/en-us/mix-database-data-value/portal/getCategoryNri',
    "GET_NRIPOST": '/v1/rest/en-us/post/portal/getdata-id',

    // Cource Page
    "GET_ALL_GRADES": "/v1/rest/en-us/Grades/filter",
    "GET_COURCES_BY_GRADE": "/v1/rest/en-us/packagedetail/getdatabyGrade/",

    // Resource Page
    "GET_ALL_RESOURCE_TYPE": "/v1/rest/en-us/Resources",
    "GET_ALL_SUBJECT": "/v1/rest/en-us/subject",
    "GET_RESOURCE_BY_SUBJECT": "/v1/rest/en-us/chapterinfo/GetResourcesListByFeaturesSp",
    "GET_RESOURCE_DETAILS": "/v1/rest/en-us/chapterinfo/GetResourcesByFeatures_subTopicSp",

    // CMS Page
    "GET_CMS_POST": "/v1/rest/en-us/post/portal/GetdataPost/",
    //contcat us page
    "CONTACT_US_DETAILS": "/StaticPagesApi/ContactusEnquiry",

    // Product Details
    "GET_PRODUCT_DETAILS": "/v1/rest/en-us/packagedetail/",
    "GET_PRODUCT_DETAILS_BY_URL": "/v1/rest/en-us/packagedetail/GetPackageByUrl",

    // Forum
    "CREATE_QUESTION": "/v1/rest/en-us/question",
    "GET_DATA_BY_CATEGORY": "/v1/rest/en-us/question/GetdatabyCatid/",
    "GET_DATA_RANDOM_BY_CATEGORY": "/v1/rest/en-us/question/GetRandomPopularQuestion?",
    "GET_SIMILAR_QUESTION": "/v1/rest/en-us/question/SimilarQuestions",
    "GET_FORUM_TAGS": "/v1/rest/en-us/mix-database-data-value/portal/getTagForum",
    // "GET_ANSWERS": "/v1/rest/en-us/answer/",
    "GET_ANSWERS": "/v1/rest/en-us/answer/GetAnswers/",
    "CREATE_ANSWER": "/v1/rest/en-us/answer",
    "GET_QUESTION": "/v1/rest/en-us/question/",
    "UPDATE_QUESTION": "/v1/rest/en-us/question/",

    // Resource Page
    "GET_SUBJECT": "/v1/rest/en-us/subject",
    "GET_CHAPTER_TITLE_BY_RESOURCE_SUBJECT_GRADE": "/v1/rest/en-us/chapterinfo/GetResourcesListByFeaturesSp/",
    "GET_TOPIC_DETAILS": "/v1/rest/en-us/chapterinfo/GetResourcesByFeatures_subTopicSp/",
    "GET_RESOURCE_BY_ID": "/v1/rest/en-us/chapterinfo/",

    // User (Login-Register)
    "ADD_USER": "/v1/rest/en-us/Users",
    "CHECK_PHONE_NO": "/v1/rest/en-us/Users/filter",
    "CHECK_EMAIL_ID": "/v1/rest/en-us/Users/filter",
    "lOGIN_USING_EMAIL_ID": "/v1/rest/en-us/Users/LoginUsingEmail",
    "FORGOT_PASSWORD_USING_EMAIL_ID": "/v1/rest/en-us/Users/ForgotPassword",
    "USER_DETAILS": "/v1/rest/en-us/userdetail",
    "USER_EDIT_DETAILS": "/v1/rest/en-us/userdetail/",
    "GET_USER_BY_ID": "/v1/rest/en-us/Users/",
    "GET_TEST_SERIES_LINK": '/v1/rest/en-us/Users/GetTestSeriesLink',
    "GET_CLASS_LINK": '/v1/rest/en-us/Users/GetEdmingleClassLink',
    "GET_ORDER_BY_USERID": "/v1/rest/en-us/order?",
    "GET_TRIAL_DETAILS": "/v1/rest/en-us/packagedetail/getdatatrialpackage/",
    "GET_GRADE_BY_ID": "/v1/rest/en-us/Grades/",
    "GET_ADDRESS_DETAILS": "/v1/rest/en-us/addressdetails",
    "GET_ADDRESS_DETAILS_FILTER": "/v1/rest/en-us/AddressDetails/filter?",
    "UPDATE_USER_DETAILS": "/v1/rest/en-us/Users/",
    "CHECK_USER": "/v1/rest/en-us/Users/UserByPhoneNumber",
    "SEND_OTP": "/v1/rest/en-us/Users/send",

    // Packages
    "GET_PACKAGE_DETAILS": "/v1/rest/en-us/packagedetail/filter",
    "GET_PRODUCT_FROM_ID": "/v1/rest/en-us/packagedetail",
    "PACKAGE_ORDER": "/v1/rest/en-us/order",
    "PACKAGE_ORDER_DETAILS": "/v1/rest/en-us/packageorderdetails",
    "CREATE_TEMP_PACKAGE": "/v1/rest/en-us/temppackageorderdetails",
    "GET_COURSES_BY_PAGE_CATEGORY":"/v1/rest/en-us/packagedetail/GetPackageCategoryList",

    // Order Page
    "CREATE_TRANSACTION": "/v1/rest/en-us/transactionresponse",

    // One-on-one page
    "BOOK_FREE_DEMO": "/BookDemo/BookAFreeDemo",
    // Blog 
    "GET_TOP_THREE": "wp-json/wp/v2/posts?_embed&per_page=3",
    "GET_BLOG_DETAILS": "wp-json/wp/v2/posts?page=",
    "GET_BLOG": "wp-json/wp/v2/posts/",
    "GET_BLOG_100": "wp-json/wp/v2/posts?per_page=10",
    "GET_BLOG_BY_ID": "wp-json/wp/v2/posts/",
    "GET_BLOG_BY_PAGE": "page/",
    "GET_BLOG_BY_PAGE_Id": "wp-json/wp/v2/media?page=",
    "GET_BLOG_BY_PAGE_POST_Id": "wp-json/wp/v2/posts?page=",
    "GET_BLOG_BY_CATEGORY": "wp-json/wp/v2/posts?categories/",
    "GET_BLOG_IMAGE": "wp-json/wp/v2/media",
    "GET_BLOG_IMAGE_BY_PAGE_ID": "wp-json/wp/v2/media?page=",
    "GET_BLOG_BY_CATEGORY_NAME": "wp-json/wp/v2/posts?categories=",
    "GET_BLOG_BY_SLUG_URL": "wp-json/wp/v2/custom-posts?slug=",
    "GET_MEDIA_BY_ID": "wp-json/wp/v2/media/",
    "SEARCH_BY_TEXT": "wp-json/wp/v2/search?search=",
    "SEARCH_BLOG_BY_TEXT": "wp-json/wp/v2/posts?search=",
    "SEARCH_BLOG_BY_TEXT_WITH_INDEX": "wp-json/wp/v2/search?search=",
    "GET_COMMENT_SECTION": "https://disqus.com/embed/comments/?",
    "GET_ALSO_ON_BLOG": "https://disqus.com/api/3.0/discovery/listRecommendations.json?",
    "GET_VOTE": "wp-admin/admin-ajax.php?action=populate_post&postID=",
    "GET_TOP_TEN": "wp-json/wp/v2/top-pages/",

    //profile
    "TOTAL_QUESTION_DETAILS_BY_USER": "/v1/rest/en-us/question/TotalQuestionDetailsByUser/",
    "TOTAL_POST_BY_USER": "/v1/rest/en-us/question/TotalPostsByUser/",
    "TOTAL_ANSWER_DETIALS_BY_USER": "/v1/rest/en-us/answer/TotalAnswerDetailsByUser/",

    //Google Sign in 
    "SAVE_GOOGLE_SIGNIN_RESPONSE": "/v1/rest/en-us/Users/GoogleSigninSaveData",
    "UPADTE_PHONE_AND_GRADE": "/v1/rest/en-us/Users/UpdateGoogleUserGradePhoneNumber?",

    //JuniorHacker
    "SUBMIT_PARENT_LEAD" :"/v1/rest/en-us/JuniorHacker/JuniorHacker"

}