import { NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import firebase from 'firebase/compat/app';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { SideNavComponent } from './layout/side-nav/side-nav.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { TransferHttpCacheModule } from '@nguniversal/common';
import 'firebase/auth';
import { TrialUserComponent } from './popupModel/trial-user/trial-user.component';
import { FreeTrialNotifyComponent } from './popupModel/free-trial-notify/free-trial-notify.component';
import { FreeDemoRegistrationComponent } from './popupModel/free-demo-registration/free-demo-registration.component';
import { GoogleRECAPTCHAModule } from './popupModel/google-recaptcha/google-recaptcha.module';

// Initialize Firebase
export const config = {
  // apiKey: 'AIzaSyDBsM2g4y7ZZAy31Pg0pL5ui3Pf-WovLMY',
  // authDomain: 'auth-asskiitians.firebaseapp.com',
  // projectId: 'auth-asskiitians',
  // storageBucket: 'auth-asskiitians.appspot.com',
  // messagingSenderId: '562194384249',
  // appId: '1:562194384249:web:4ebec8ca698b5857e65ba8',
  // apiKey: "AIzaSyC0uYWkf_5lu4-aKL8uh8GDlAFtn14BPqg",
  // authDomain: "auth-askiitians.firebaseapp.com",
  // projectId: "auth-askiitians",
  // storageBucket: "auth-askiitians.appspot.com",
  // messagingSenderId: "827924081645",
  // appId: "1:827924081645:web:8c551629cf6698cef9d5e4",
  // measurementId: "G-YGNP928MTB"
  apiKey: "AIzaSyD7UdBAnqUenNxSTDdUy8DITZRdgpo7VJs",
  authDomain: "askiitians-web.firebaseapp.com",
  projectId: "askiitians-web",
  storageBucket: "askiitians-web.appspot.com",
  messagingSenderId: "656606676575",
  appId: "1:656606676575:web:fc4edf971ff5cbd27e3dea",
  measurementId: "G-9K9KPRVWDE"
};
firebase.initializeApp(config);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent, //add module --done
    TrialUserComponent, //add module  --done
    FreeTrialNotifyComponent,  //add module  --done
    SideNavComponent, //add module --done
    // FreeDemoRegistrationComponent, //add module  --done
    PageNotFoundComponent

  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    TransferHttpCacheModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    HttpClientModule,
    GoogleRECAPTCHAModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule { }
