import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from './canonical.service';

@Injectable({
  providedIn: 'root',
})
export class MetaContentService {

  keyword!: any;
  description!: any;
  Title!: any;
  canonical!: any;
  questionSimpleText: any;
  answerText: any;


  constructor(private meta: Meta,
    private title: Title,
    private canonicalService: CanonicalService,

  ) { }

  updateMetaContent(keyword: any, description: any, title: any, answertext: any, canonical: any, pageCategory: any, robot?: any) {
    if (pageCategory == 'forum') {
      this.updateCategoryMetaContent(keyword, description, title, canonical);
    }
    else if (pageCategory == 'readAnswer') {
      this.updateQuestionMetaContent(description, answertext, canonical);
    }
    else if (pageCategory == 'cms') {
      this.updateCMSMetaContent(keyword, description, title, canonical, robot)
    }
    else if (pageCategory == 'package') {
      this.updatePakageMetaContent(description, title);
    }
    else if (pageCategory == 'Error') {
      this.UpdateErrorContent(title);
    }
    else {
    
    }
  }

  updateCategoryMetaContent(keyword: any, description: any, title: any, canonical: any) {

    this.meta.updateTag(
      { name: 'description', content: description },
      'name=description'
    ),
      this.meta.updateTag(
        { name: 'keywords', content: keyword },
        'name=keywords'
      ),
      this.canonicalService.setCanonicalURL(canonical);
    this.title.setTitle(title);
  }
  updateQuestionMetaContent(description: any, answertext: any, canonical: any) {

    this.description = description;
    this.answerText = answertext;

    if (this.description?.length > 70) {
      this.Title = this.description?.substr(0, 69).concat(' - askIITians');
    }
    else {
      this.Title = this.description?.concat(' - askIITians');
    }
    if (this.description?.length > 160) {
      this.description = this.description?.slice(0, 159).trim();
    }
    else {
      this.description = this.description;
    }

    if (answertext == null) {
      if (this.description.length < 160) {
        this.description = this.description.substring(0, this.description.length);
      }
      else {
        this.description = this.description.substring(0, 160);
      }
    }
    else {
      if (this.description.length < 160) {
        this.description = this.description + ' ' + this.answerText;
        if (this.description.length < 160) {
          this.description = this.description.substring(0, this.description.length);
        }
        else {
          this.description = this.description.substring(0, 160);
        }
      }
      else {
        this.description = this.description.substring(0, 160);
      }
    }


    this.meta.updateTag(
      { name: 'description', content: this.description },
      'name=description'
    )

    this.title.setTitle(this.Title);
    this.canonicalService.setCanonicalURL(canonical);
    this.meta.removeTag('name="keywords"');

  }

  updateCMSMetaContent(keyword: any, description: any, title: any, canonical: any, robot: any) {
    this.description = description;

    if ((this.description == null || this.description == undefined || this.description == "") && (canonical != 'faqs.aspx')) {
      this.meta.updateTag(
        { name: 'description', content: "" },
        'name=description'
      ),
        this.meta.updateTag(
          { name: 'keywords', content: "" },
          'name=keywords'
        ),
        this.meta.updateTag(
          { name: 'robots', content: "" },
          'name=robots'
        ),
        this.title.setTitle(title);
    }
    else if ((this.description == null || this.description == undefined || this.description == "") && (canonical == 'faqs.aspx')) {
      this.meta.updateTag(
        { name: 'description', content: this.description },
        'name=description'
      ),
        this.meta.updateTag(
          { name: 'keywords', content: keyword },
          'name=keywords'
        ),
        this.meta.updateTag(
          { name: 'robots', content: robot },
          'name=robots'
        )

      this.canonicalService.setCanonicalURL(canonical);
      this.title.setTitle(title);
    }
    else {
      this.meta.updateTag(
        { name: 'description', content: this.description },
        'name=description'
      ),
        this.meta.updateTag(
          { name: 'keywords', content: keyword },
          'name=keywords'
        ),
        this.meta.updateTag(
          { name: 'robots', content: robot },
          'name=robots'
        )

      this.canonicalService.setCanonicalURL(canonical);
      this.title.setTitle(title);
    }
  }
  updatePakageMetaContent(description: any, title: any) {
    this.description = description;
    this.meta.updateTag(
      { name: 'description', content: this.description },
      'name=description'
    )
    this.title.setTitle(title);
  }

  UpdateErrorContent(title: any) {
    this.title.setTitle(title);
  }
}
