import { DatePipe, isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GradeModel } from 'src/app/Models/GradeModel';
import { Registration } from 'src/app/Models/RegistrationModel';
import { CacheService } from 'src/app/services/cache.service';
import { CourceService } from 'src/app/services/cource.service';
import { NotificationService } from 'src/app/services/notification.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { StorageKey, StorageType } from 'src/app/storage-key';
import { NotificationType } from 'src/app/enum/NotificationType';
import { phoneNumberValidator } from 'src/app/country-picker/number-validator';
import { TrialService } from 'src/app/services/trial.service';
import { TrialUserComponent } from '../trial-user/trial-user.component';
import { ClientIPAddressService } from 'src/app/services/client-ipaddress.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  @Output() isUserLogged: EventEmitter<any> = new EventEmitter();
  @Output() userid: EventEmitter<any> = new EventEmitter();
  @Output() isRegistered: EventEmitter<any> = new EventEmitter();

  @Input() sourceId: number = 1;
  @Input() isCMS!: string;
  
  form!: FormGroup;
  showOTPInput: boolean = false;
  separateDialCode = true;
  getGrade: Array<GradeModel> = [];
  userDetails: any;
  registration!: Registration;
  activeModal: any;
  recaptchaVerifier: any;
  userID!: number;
  phoneLenght: any;
  cssClass = 'btn-cms-invalid';
  isCMSpage: boolean = false;
  isBlogPage: boolean = false;
  isWritten: boolean = false;
  isAsperCountry: boolean = true;
  phoneNumber: string = '';
  @Input() isShowCrossbtn: boolean = false;
  @Input() password!: string
  isSubmitted: boolean = false;
  UserId: number | undefined;
  clientIPAdress!:string; 
  constructor(
    private courceService: CourceService,
    private modalService: NgbModal,
    private storageService: StorageService,
    private cacheService: CacheService,
    private userService: UserService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private trialService: TrialService,
    private clientIPAdressService:ClientIPAddressService,
    @Inject(PLATFORM_ID) private platformId: any) {
    this.form = this.formBuilder.group({
      phoneNumber: [Validators.required],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      gradeId: ['', Validators.required,],
    });
  }

  ngOnInit() {
    this.courceService.getAllGrades().subscribe((res) => {
      this.getGrade = res;
    });  
    this.form.patchValue({
      email: this.storageService.getItem(StorageKey.EMAIL, StorageType.LOCAL)
    });
    this.phoneNumber = this.storageService.getItem(StorageKey.MOBILE_NO, StorageType.LOCAL);
    if(this.phoneNumber==null)
    {
      this.form.patchValue({
        phoneNumber : ''
      });
    }
    else
    {
      this.phoneNumber =this.phoneNumber;
    }
    this.clientIPAdressService.getClientIp().subscribe((ip) => {
      console.log('Client IP:', ip);
      this.clientIPAdress = ip;
    });
  }
  get f() {
    return this.form?.controls;
  }
  validateUser() {
    
    if (!this.form.invalid) {
      if(this.phoneNumber == null)
      {
        this.phoneNumber =this.form.value.phoneNumber
      }
      else
      {
        this.phoneNumber = this.phoneNumber;
      }
       
      this.userService.checkUser(this.phoneNumber).subscribe(
        {
          next: (isExistUser) => {   
            this.userID = isExistUser.id;
            if (isExistUser.id) {
              /*
              if (isExistUser.name == null || isExistUser.name == ''|| isExistUser.name == undefined) {
                this.updateUserDetails();
              } else {
                this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
                this.cacheService.setIsUserLoggedIn(true);
                this.getUserDetailsById(this.userID);
                this.isRegistered.emit(false);
                this.closeModal();
                this.notificationService.notifyMessage(NotificationType.Success, `You're successfully logged in!`);
                this.getUserStatus();
              }
              */
             
             this.updateUserDetails();
             this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
             this.cacheService.setIsUserLoggedIn(true);
             this.getUserDetailsById(this.userID);
             this.isRegistered.emit(false);
             this.closeModal();
             this.notificationService.notifyMessage(NotificationType.Success, `You're successfully logged in!`);
             this.getUserStatus();

            } else {
              this.updateUserDetails();
            }
          },
          error: () => {
            this.registerUser();
          }
        });
    } else {
      this.form.markAllAsTouched();
      return;
    }
  }

  transformDate(date: string | number | Date) {
    return this.datePipe.transform(date, "yyyy-MM-ddThh:mm:ss.SSS'Z'");
  }
  registerUser() {
    this.registration = {
      id: 0,
      name: this.form.value.name,
      email: this.form.value.email,
      isActive: true,
      password: this.password,
      mobile: this.form.value.phoneNumber,
      createdDateTime: this.transformDate(new Date())?.toString(),
      modifiedBy: "",
      userTypeId: 1,
      lastModified: this.transformDate(new Date())?.toString(),
      registrationUrl: String(window.location.pathname),
      sourceNameId: this.sourceId,
      ipCountry: '',
      ipAddress: this.clientIPAdress,
      city: '',
      userDetails: {
        id: 0,
        grade: String(this.form.value.gradeId),
        userId: 0,
        schoolName: "",
        modifiedDate: this.transformDate(new Date())?.toString(),
      }
    }
    if (this.registration.mobile != null) {
      this.userService.create(this.registration).subscribe(data => {
        if (data.result.value.id != null) {
          this.userID = data.result.value.id;
          this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
          this.cacheService.setIsUserLoggedIn(true);
          this.getUserDetailsById(this.userID);
          this.isRegistered.emit(false);
          this.closeModal();
          this.notificationService.notifyMessage(NotificationType.Success, `You're Register Successfully!`);
        }
        else { this.notificationService.notifyMessage(NotificationType.Success, `Sorry ! Unable to Register you!`); }
      });
    }
  }
  getUserDetailsById(id: number) { 
    this.userService.getUserFromID(id).subscribe(res => {
      if(res)
      {
        this.userDetails = res;
        this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
        this.storageService.setItem(StorageKey.USER, JSON.stringify(res), StorageType.LOCAL);
        this.cacheService.onUserDetailsChange(res);
      }
    });
  }
  loginUser(userData: any) {
    this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
    this.cacheService.setIsUserLoggedIn(true);
    this.getUserDetailsById(userData.id);
    this.isUserLogged.emit(this.storageService.getItem(StorageKey.IS_LOGGED_IN, StorageType.LOCAL));
    this.notificationService.notifyMessage(NotificationType.Success, `You're successfully logged in!`);
    if (this.userID) {
      this.userid.emit(this.userID);
    }
    if (this.userID != null || this.userID != undefined || localStorage.getItem('isLoggedIn') == "true") {
      if (isPlatformBrowser(this.platformId)) {
        const section = document.querySelector('.live-img-sec') as HTMLElement;
        const section2 = document.querySelector('#nriAvailNowDiv') as HTMLElement;
        const section3 = document.querySelector('.join-now-section') as HTMLElement;
        const element = document.querySelector('#textIITNRI') as HTMLElement;
        const element2 = document.querySelector('#textNEETNRI') as HTMLElement;
        if (section && section2 && section3 && (element || element2)) {
          section.style.display = 'none';
          section2.style.display = 'none';
          section3.style.display = 'none';
          if (element) {
            element.classList.remove('col-md-8');
          }
          if (element2) {
            element2.classList.remove('col-md-8');
          }
        }
      }
    }
    this.closeModal();
  }
  checkNumber() {
    if (this.form.value.phoneNumber?.length < 10 || this.form.value.name == undefined || this.form.value.email == undefined) {
      this.cssClass = 'btn-cms-invalid';
    }
    else if (this.form.value.phoneNumber?.length == 11 || this.form.value.name != undefined || this.form.value.email != undefined || this.form.value.gradeId != undefined) {
      this.cssClass = 'btn-cms-valid';
    }
    else {
      this.cssClass = 'btn-cms-valid';
    }
  }
  closeModal() {
    this.modalService.dismissAll();
  }
  ischange() {
   
    const isValid = phoneNumberValidator(this.form.value.phoneNumber);
    if (isValid == true) {
      this.isWritten = true;
      this.isAsperCountry = true;
    }

  }
  updateUserDetails() {
   
    var mobileNumber: any = this.form.value.phoneNumber;
    if (
      !mobileNumber ||
      typeof mobileNumber === 'function' // Check if it's a function
    ) {
      mobileNumber = this.phoneNumber; // Set the default phone number value
    }

    this.registration = {
      id: this.userID,
      name: this.form.value.name,
      email: this.form.value.email,
      isActive: true,
      password: this.password,
      mobile: mobileNumber,
      createdDateTime: this.transformDate(new Date())?.toString(),
      modifiedBy: '',
      userTypeId: 1,
      lastModified: this.transformDate(new Date())?.toString(),
      registrationUrl: String(window.location.pathname),
      sourceNameId: this.sourceId,
      ipCountry: '',
      ipAddress: this.clientIPAdress,
      city: '',
      userDetails: {
        id: 0,
        grade: String(this.form.value.gradeId),
        userId: this.userID,
        schoolName: '',
        modifiedDate: this.transformDate(new Date())?.toString(),
      },
    };
   
    this.userService.updateUserDetail(this.userID, this.registration).subscribe((data) => {
      this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
      this.cacheService.setIsUserLoggedIn(true);
      this.getUserDetailsById(this.userID);
      this.isRegistered.emit(true);
      this.closeModal();
      this.notificationService.notifyMessage(NotificationType.Success, `You're Register Successfully!`);
      this.getUserStatus();
    });

  }
  getUserStatus(): any {
    if (this.UserId) {
      this.trialService.GetOrder(this.UserId).subscribe((data) => {
        if (data.length > 0) {
        }
        else {
          setTimeout(() => {
            const modalRef = this.modalService.open(TrialUserComponent, { centered: true, size: 'xl', backdrop: 'static' });
            modalRef.componentInstance.UserId = this.UserId;
            modalRef.componentInstance.GradeId = this.form.value.gradeId;
          }, 3000);
        }
      });
    }
  }
}
