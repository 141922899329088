<div class="login_page" *ngIf="otpVerified">
  <section class="register">
    <div class="modal-header close_btn_align">
      <h4 class="modal-title"></h4>
      <button type="button" class="btn-close  btn-close-white" data-bs-dismiss="modal"
          (click)="closeModal()"></button>
  </div>
      <div class=" position-relative">
          <div class="box">
              <form [formGroup]="form" class="px-2 OTPVerify">
                  <div *ngIf="isOTPSent" id="recaptcha-container"></div>
                  <div class="otp-input" id="show_otp">
                    <!-- <p id="otp_mobile" #hello></p> -->
                    <label class="mb-1 clr_gray f_600">Enter OTP</label>
                    <div class="input-container d-flex flex-row justify-content-center inputs mb-3" style="display:flex;">
                      <input type="text" class="m-1 text-center form-control  inputs_data" maxlength="1"
                        formControlName="verificationCode1" (keyup)="keyUpEvent($event)" onlyNumber autofocus/>
                      <input type="text" class="m-1 text-center form-control inputs_data" maxlength="1"
                        formControlName="verificationCode2" (keyup)="keyUpEvent($event)" onlyNumber />
                      <input type="text" class="m-1 text-center form-control inputs_data" maxlength="1"
                        formControlName="verificationCode3" (keyup)="keyUpEvent($event)"  onlyNumber/>
                      <input type="text" class="m-1 text-center form-control inputs_data" maxlength="1"
                        formControlName="verificationCode4" (keyup)="keyUpEvent($event)"  onlyNumber/>
                      <input type="text" class="m-1 text-center form-control inputs_data" maxlength="1"
                        formControlName="verificationCode5" (keyup)="keyUpEvent($event)"  onlyNumber/>
                      <input type="text" class="m-1 text-center form-control inputs_data" maxlength="1"
                        formControlName="verificationCode6" (keyup)="keyUpEvent($event)" onlyNumber/>
                    </div>
                  </div>
                  <div *ngIf="!isOTPVerfiedMessage" class="alert alert-danger">
                    <div>Please Enter Valid OTP</div>
                  </div>
                  <div class="resend_otp" style="line-height: 2">
                    <p>
                      Did Not Received OTP?<a (click)="resendOTP()"> Send OTP Again</a><br>
                      <a class="othrNo" (click)="SendToAnotherNumber($event)"> Send OTP To Another Number</a>
                    </p>
                  </div>
                  <div class="login_hide">
                    <div class="mt-4 text-center login_submit">
                      <button type="submit" class="btn login_btn" [disabled]="!f['verificationCode6'].valid"
                        (click)="validateOTP()">Validate</button>
                    </div>
                  </div>
              </form>
          </div>
      </div>
  </section>
</div>