import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CanonicalService {
  constructor(@Inject(DOCUMENT) private dom: any) {}
  existsCanonical!: any;
  canonicalLink: any;
  data: any;
  link: HTMLLinkElement = this.dom.createElement('link');

  setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;

    let updatedUrl = canURL.replace(/\/?forums\/forums/, 'forums');
    updatedUrl = updatedUrl.replace(/^\//, '');
    this.canonicalLink = this.link;
    this.link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(this.link);

    // Set the canonical URL
    this.existsCanonical = 'https://www.askiitians.com/' + updatedUrl;
    this.link.setAttribute('href', 'https://www.askiitians.com/' + updatedUrl);
  }

  checkCanonical(canonicalURL: any): boolean {
    this.existsCanonical = this.dom.location.href.slice(29).toLowerCase();
    if (canonicalURL == this.existsCanonical) {
      return true;
    } else {
      return false;
    }
  }
}
