<div  [ngClass]="{'toggle-desktop position-fixed': !childData, 'mobile-content': childData ,'tushar tushar1' : expandMenu}">
    <div [ngClass]="{'desktop-content': !childData, 'mobile-content': childData}">
        <ul>
            <li class="active">
                <a routerLink="/user/dashboard-component" (click)="expandMenu = !expandMenu;sendDataToParent()" routerLinkActive="active" ariaCurrentWhenActive="page"
                    class="d-flex">
                    <img src="https://files.askiitians.com/static_content/assets/images/header/home.png" width="19" height="20"> &nbsp; &nbsp; Home
                </a>
            </li>
            <li>
                <a (click)="sendDataToParent(); getClassLink();" title="My Class" class="d-flex" href="javascript:void(0);">
                    <img src="https://files.askiitians.com/static_content/assets/images/header/teaching.png" width="22" height="19">
                    &nbsp; &nbsp;My Class
                </a>
            </li>
            <li>
                <a (click)="expandMenu = !expandMenu;sendDataToParent() ;getTestSeriesLimk();" title="Test Series" target="_blank" class="d-flex">
                    <img src="https://files.askiitians.com/static_content/assets/images/header/teaching.png" width="22" height="19" >
                    &nbsp; &nbsp; Test Series
                </a>
            </li>

            <li *ngIf="isPackage">
                <a title="My Resources" routerLink="/resources-page-component" (click)="expandMenu = !expandMenu;sendDataToParent()" class="d-flex">
                    <img src="https://files.askiitians.com/static_content/assets/images/header/resources.png" width="22" height="19" >
                    &nbsp; &nbsp; My Resources
                </a>
            </li>
           
            <li>
                <a  title="Question"  routerLink="/forums/" routerLinkActive="active" (click)="expandMenu = !expandMenu;sendDataToParent()" ariaCurrentWhenActive="page" class="d-flex">
                    <img src="https://files.askiitians.com/static_content/assets/images/header/question.png" width="22" height="19" >
                    &nbsp; &nbsp; Question
                </a>
            </li>
            <li>
                <a title="User Profile"   routerLink="/user/profile-component" (click)="expandMenu = !expandMenu;sendDataToParent()" routerLinkActive="active" ariaCurrentWhenActive="page"
                    class="d-flex">
                    <img src="https://files.askiitians.com/static_content/assets/images/header/profile.png" width="22" height="19" >
                   &nbsp; &nbsp; User Profile
                </a>
            </li>
        </ul>
    </div>
</div>