import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationType } from 'src/app/enum/NotificationType';
import { MenuListItems } from 'src/app/Models/ModelsList/menuListItem';
import { MenuModel } from 'src/app/Models/menuModel';
import { SourceIdEnum } from 'src/app/Models/sourceIdEnumModel';
import { FreeDemoRegistrationComponent } from 'src/app/popupModel/free-demo-registration/free-demo-registration.component';
import { CacheService } from 'src/app/services/cache.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProductService } from 'src/app/services/product.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { StorageKey, StorageType } from 'src/app/storage-key';
import { LoginWithEmailComponent } from 'src/app/popupModel/login-with-email/login-with-email.component';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  
})
export class HeaderComponent implements OnInit {
  toggleMobileMenu: boolean = false;
  isLogin: boolean = false;
  expandMenu: boolean = false;
  isSideNavClicked: boolean = false;
  isVerticalLayout = true;
  expandWithoutMenu: boolean = false;
  mobileActiveMenu: string = 'menu';
  dekstopActiveMenu: string = '';
  userDetails: any;
  menuItems: Array<MenuModel> = [] as Array<MenuModel>;
  notesmenuItems: Array<MenuModel> = [] as Array<MenuModel>;
  activeMenuItems: any[] = [];
  activeMenuName: string = '';
  activeChildMenuName: string = '';
  profileClssLink: any;
  ProfileTestLink: any;
  expandSubChildMenu: boolean = false;
  expandSubMenu: boolean = false;
  expandSubMenuNotes: boolean = false;
  scrollingClass: string = '';
  productData: any;
  isPackage: boolean = false;
  Appgradeid: any;
  currentRoute!: string;
  ifHome: boolean = false;
  showUserMenu: boolean = false;
  getHtml!: HTMLElement;
  show = false;
  _routeSubscriber: any;
  cachaSubscribe: any;
  scrollSubscriber: any;
  parentList: boolean = false;
  childList: boolean = false;
  subChildList: boolean = false;
  hideSideNav: boolean = false;

  constructor(
    private modalService: NgbModal,
    private cachaService: CacheService,
    private router: Router,
    private storage: StorageService,
    private userService: UserService,
    private productService: ProductService,
    private notificationService: NotificationService,
    @Inject(PLATFORM_ID) private platformId: any,

  ) {
  }

  ngOnInit(): void {
    let IsLogin = this.storage.getItem(StorageKey.IS_LOGGED_IN, StorageType.LOCAL);
    this.isLogin = Boolean(IsLogin);
    this.ifHome = this.isLogin;

    this.cachaSubscribe = this.cachaService._userDetails.subscribe((res) => {
      this.isLogin = res != null;
      this.ifHome = this.isLogin;
      this.userDetails = res;
      if (res != null) {
        // this.getUserLinks();
        this.getUserOrders();
      }
    });

    this.cachaService._isUserLoggedIn.subscribe((res) => {
      this.isLogin = res;
      if (this.isLogin == false) {
        if (this.storage.getItem(StorageKey.IS_LOGGED_IN, StorageType.LOCAL) == 'true') {
          this.isLogin = true;
        }
        else {
          this.isLogin = false;
        }
      }
      else {
        this.isLogin = res;
      }
    });


    // this.scrollSubscriber = this.scrollService.scrollPosition.subscribe((scrollClass) => {
    //   this.scrollingClass = scrollClass;
    //   if (scrollClass != '') {
    //     this.expandMenu = false;
    //     this.showUserMenu = false;
    //     this.cachaService.onExpandUserMenu(this.expandMenu);
    //   }
    //   //Note:remove scrolling hide while open a side menu(my-instruction-right) class mobile view. 
    //   if (this.toggleMobileMenu == true) {
    //     this.scrollingClass = '';
    //   }
    // });

    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        this.currentRoute = ev.url;
      }
    })

    // if (isPlatformBrowser(this.platformId)) {
    //   $(document).ready(function () {
    //     var menuList = $('#jhNavbar').find('.nav-link');

    //     menuList.on('click', function (e: any) {
    //       e.preventDefault();
    //       let ancer = $(e.target)[0];
    //       let elId = $(ancer).attr('href')
    //       $('html, body').animate({
    //         scrollTop: $(elId).offset().top
    //       }, 500);
    //     })

    //   });
    // }
  }
  getUserLinks() {
    this.userService.GetEdmingleClassLink(this.userDetails.name, this.userDetails.email, '').subscribe((res) => {
      this.profileClssLink = res;
    });

    this.userService.GetTestSeriesLink(this.userDetails.id.toString()).subscribe((res) => {
      this.ProfileTestLink = res;
    });
  }

  getUserOrders() {
  this.productService.getOrderFromUserID(this.userDetails?.id).subscribe((data) => {
    this.productData = data.items;
    for (const item of this.productData) {
      if (
        item.userId === this.userDetails?.id &&
        item.transactionId > 0 &&
        item.packagedetail && 
        item.packagedetail.length > 0 
      ) {
        this.isPackage = true;
        this.Appgradeid = item.packagedetail[0].gradeid;
      }
    }
  });
}

  ngOnDestroy() {
    this.cachaSubscribe.unsubscribe();
    // this.scrollSubscriber.unsubscribe();
    // this._routeSubscriber.unsubscribe();
  }

  gotoMyResources() {
    this.router.navigate(['/my-resources-component/', this.Appgradeid, 2, 1]);
  }

  expandUserMenu() {
    this.isVerticalLayout = !this.isVerticalLayout;
    this.cachaService.onExpandUserMenu(this.expandMenu);
  }

  openlogin() {
    const sourceId = SourceIdEnum.Login;
    const loginModelRef = this.modalService.open(LoginWithEmailComponent, { centered: true, backdrop: 'static' });
    loginModelRef.componentInstance.sourceId = sourceId;
    loginModelRef.componentInstance.isUserLogged.subscribe((isUserLogged: any) => {
      if (isUserLogged) {
        this.isLogin = isUserLogged;
        this.cachaService.setIsUserLoggedIn(true);
      }
    });

  }


  logout() {
    this.storage.setItem(StorageKey.IS_LOGGED_IN, 'false', StorageType.LOCAL);
    this.cachaService.onUserDetailsChange(null);
    this.cachaService.setIsUserLoggedIn(false);
    this.storage.clear(StorageType.LOCAL);
    this.router.navigate(['/home']);
    this.notificationService.notifyMessage(NotificationType.Success, `You're successfully logged out!`);
  }

  onclick_exam_info() {
    this.show = false;
  }
  onclick_notes() {
    this.show = false;
  }
  over_notes() {
    this.notesmenuItems = MenuListItems.NotesMenuItems;
    // this.show = true;
  }
  over_exam() {
    this.menuItems = MenuListItems.MenuItems;
    // this.show = true;
  }
  over_exam_click() {
    this.menuItems = MenuListItems.MenuItems;
  }

  onMouseEnterOnExamInfo()
  {
    this.menuItems = MenuListItems.MenuItems;
     this.show = true;
     this.childList = true;
  }
  onMouseLeaveOnExamInfo()
  {
    this.childList = false;
  }
  
  onMouseEnterOnNotes()
  {
    this.notesmenuItems = MenuListItems.NotesMenuItems;
    this.show = true;
  }
  bookAFreeClass() {
    if (!this.userDetails) {
      const sourceId = SourceIdEnum.Login;
      const modalRef = this.modalService.open(LoginWithEmailComponent, { centered: true, backdrop: 'static' });
      modalRef.componentInstance.sourceId = sourceId;
    } else {
      const sourceId = SourceIdEnum.FreeDemo;
      const modalRef = this.modalService.open(FreeDemoRegistrationComponent, { centered: true, backdrop: 'static' });
      modalRef.componentInstance.UserName = this.userDetails.name;
      modalRef.componentInstance.Phone = this.userDetails.mobile;
      modalRef.componentInstance.sourceId = sourceId;
    }
  }
  receiveDataFromChild(data: boolean) {
    this.toggleMobileMenu = data;
  }
  examInfo() {
    this.expandSubMenu = !this.expandSubMenu;
    this.menuItems = MenuListItems.MenuItems;
  }
  notesInfo() {
    this.expandSubMenuNotes = !this.expandSubMenuNotes
    this.notesmenuItems = MenuListItems.NotesMenuItems;
  }
  sideNavigation() {
    this.isSideNavClicked = true;
  }
  manageSideNav(eventData: any) {
    this.expandMenu = !this.expandMenu;
  }

  closeSideNav() {
    // this.hideSideNav = true;
    this.expandMenu = false;
    this.cachaService.closeSideNavigation(false);
  }
}
